import React from "react";
import { css } from "goober";
import { t } from "i18next";

import { appURLs } from "@/app/constants.mjs";
import GameGuideTable from "@/marketing/GameGuideTable.jsx";
import GameGuideTileLg from "@/marketing/GameGuideTileLg.jsx";
import { SquareAvatar } from "@/shared/Avatar.jsx";
import getTierIcon from "@/shared/get-tier-icon.mjs";

const PALS = {
  paladius: {
    id: "paladius",
    label: ["gen-palworld:paladius.name", "Paladius"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/SaintCentaur.webp`,
    hp: 130,
    shotAttack: 120,
    defense: 145,
  },
  necromus: {
    id: "necromus",
    label: ["gen-palworld:necromus.name", "Necromus"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/BlackCentaur.webp`,
    hp: 130,
    shotAttack: 145,
    defense: 120,
  },
  frostallionNoct: {
    id: "frostallion-noct",
    label: ["gen-palworld:frostallion-noct.name", "Frostallion Noct"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/IceHorse_Dark.webp`,
    hp: 140,
    shotAttack: 140,
    defense: 135,
  },
  jetragon: {
    id: "jetragon",
    label: ["gen-palworld:jetragon.name", "Jetragon"],
    imageSrc: `${appURLs.CDN_PLAIN}/palworld/img/Pals/JetDragon.webp`,
    hp: 110,
    shotAttack: 140,
    defense: 110,
  },
} as const;

const rowPals = [
  PALS.paladius,
  PALS.necromus,
  PALS.frostallionNoct,
  PALS.jetragon,
];

export default function HomeTierListsTile() {
  const Icon = getTierIcon(1);
  return (
    <div className={TileContainer()}>
      <GameGuideTileLg
        title={["palworld:pals.tierLists", "Tier Lists"]}
        description={[
          "home:guides.palworld.breedingCalculator.description",
          "Dive into the world of Palworld with our comprehensive Pal Tier List!",
        ]}
        buttonText={["common:navigation.viewTierLists", "View Tier Lists"]}
        href={"/palworld/tier-list/combat"}
      >
        <GameGuideTable className={Table()}>
          <GameGuideTable.Header>
            <div className="pal-col">{t("palworld:name", "Name")}</div>
            <div>{t("palworld:tier", "Tier")}</div>
            <div className="stat">{t("palworld:hp", "HP")}</div>
            <div className="stat">{t("palworld:atk", "ATK")}</div>
            <div className="stat">{t("palworld:def", "DEF")}</div>
          </GameGuideTable.Header>
          <GameGuideTable.Body numRows={4}>
            {rowPals.map((pal, i) => (
              <GameGuideTable.Row
                key={i}
                link={`/palworld/database/pals/${pal.id}`}
              >
                <div className="flex gap-3 align-center pal-col">
                  <SquareAvatar avatar={pal.imageSrc} title={t(...pal.label)} />
                </div>
                <div>
                  <Icon />
                </div>
                <div className="stat">{pal.hp}</div>
                <div className="stat">{pal.shotAttack}</div>
                <div className="stat">{pal.defense}</div>
              </GameGuideTable.Row>
            ))}
          </GameGuideTable.Body>
        </GameGuideTable>
      </GameGuideTileLg>
    </div>
  );
}

const TileContainer = () => css`
  .card-contents {
    min-height: auto;
  }
`;

const Table = () => css`
  img {
    border: 1px solid var(--shade3-15);
    background: var(--shade3-15);
  }

  .pal-name {
    color: #e3e5ea;
  }

  .pal-col {
    min-width: 151px;
  }

  .stat {
    width: 7%;
  }
`;
